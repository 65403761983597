// assets
import CardTimeline from "./assets/img/system-uicons_card-timeline.png";

import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Img,
  Link,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Spacer,
  useBreakpointValue
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
// import { columnsDataComplex } from "../dataTables/variables/columnsData";
import ComplexTable from "./components/ComplexTable";
import tableDataComplex from "../../admin/default/variables/tableDataComplex.json";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Cards } from "./data/ProjectData";
import CustomCard from "./components/CustomCard";
import { HSeparator } from "components/separator/Separator";
import AvatarImg from "views/admin/project/assets/img/avatar1.png";
import ProjectCard from "./components/ProjectCard";
import ProjectCard2 from "./components/ProjectCard2";
import { OngoingProjects } from "./data/ProjectData";
import { CiCirclePlus } from 'react-icons/ci';
import { BsThreeDotsVertical, BsChevronBarLeft, BsChevronBarRight } from 'react-icons/bs';

import {
  ColumnData,
  RowData,
  EnquiryColumn,
  EnquiryRow,
  PaymentHistoryRow,
  PaymentHistoryColumn
} from "./data/ProjectData";
import EnquiryTable from "./components/EnquiryTable";
import PaymentHistoryTable from "./components/OrderHistoryTable";

const Project = () => {
  const [cards, setCards] = useState([]);
  const [ongoingProjects, setOngoingProjects] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [enquiryColumnData, setEnquiryColumnData] = useState([]);
  const [enquiryRowData, setEnquiryRowData] = useState([]);
  const [paymentHistoryColumnData, setPaymentHistoryColumnData] = useState([]);
  const [paymentHistoryRowData, setPaymentHistoryRowData] = useState([]);
  // const itemsPerPage=6;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCards(Cards);
    setOngoingProjects(OngoingProjects);
    setColumnData(ColumnData);
    setRowData(RowData);
    setEnquiryColumnData(EnquiryColumn);
    setEnquiryRowData(EnquiryRow);
    setPaymentHistoryColumnData(PaymentHistoryColumn);
    setPaymentHistoryRowData(PaymentHistoryRow);
  });

  const handleNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }
  const handlePrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
  }
  const isMobile = useBreakpointValue({ base: true, md: false });
  const itemsPerPage = isMobile ? 3 : 6;
  const totalPages = Math.ceil(ongoingProjects.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedProjects = ongoingProjects.slice(startIndex, endIndex)


  return (
    <>
      <Text fontWeight="bold">Overview</Text>
      <Flex mt="2rem" flexWrap="wrap" justifyContent="space-between">
        {cards.map((item, index) => {
          return <CustomCard key={index} card={item} />;
        })}
      </Flex>

      <Flex mt="2rem" flexWrap="wrap" justifyContent="space-between">
        {cards.map((item, index) => (
          <CustomCard key={index} card={item} />
        ))}
      </Flex>

      <Flex mt="2rem"
        backgroundColor="#FFFFFF"
        minHeight="15rem"
        borderRadius="2rem"
        boxShadow="0px 4px 38px 0px #407BFF26">
        <Tabs>
          <Flex mt="1rem" alignItems="center">
            <TabList variant="unstyle">
              <Tab>Ongoing Projects</Tab>
              <Tab>Completed Projects</Tab>
            </TabList>

            {/* Spacer pushes the elements to the right */}
            <Spacer />

            <Button
              alignItems="center"
              backgroundColor="#407BFF"
              color="#FFFFFF"
              cursor="pointer"
              borderRadius="2rem"
            >
              <Icon as={CiCirclePlus} w="2rem" h="2rem" />
              <Text ml="1rem">Add Project</Text>
            </Button>
            <Icon as={BsThreeDotsVertical} ml="1rem" />
          </Flex>

          <TabPanels>
            <TabPanel>
              <Flex
                mt="1rem"
                flexWrap="wrap"
                // flexDirection="row"
                justifyContent="space-between"
                flexDirection={{ base: "column", md: "row" }}
                gap="4"
              >
                {displayedProjects.map((item, index) => (
                  <Box key={index} w={{ base: "100%", md: "30%" }} mb={{ base: "1rem", md: "0" }}>
                    <ProjectCard props={item} />
                  </Box>
                ))}
              </Flex>
              {/* Pagination Controls */}
              <Flex justifyContent="center" alignItems="center">
                <Icon
                  as={BsChevronBarLeft}
                  onClick={handlePrev}
                  backgroundColor="#3965FF"
                  borderRadius="2rem"
                  color="#FFFFFF"
                  padding="5px"
                  width="2rem"
                  height="2rem"
                />
                <Text m="0 1rem">
                  Page {currentPage} of {totalPages}
                </Text>
                <Icon
                  as={BsChevronBarRight}
                  onClick={handleNext}
                  backgroundColor="#3965FF"
                  borderRadius="2rem"
                  color="#FFFFFF"
                  padding="5px"
                  width="2rem"
                  height="2rem"
                />
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex
                mt="1rem"
                flexWrap="wrap"
                // flexDirection="row"
                justifyContent="space-between"
                flexDirection={{ base: "column", md: "row" }}
                gap="4"
              >
                {displayedProjects.map((item, index) => (
                  <Box key={index} w={{ base: "100%", md: "30%" }} mb={{ base: "1rem", md: "0" }}>
                    <ProjectCard2 props={item} />
                  </Box>
                ))}
              </Flex>
              {/* Additional TabPanel content */}
            </TabPanel>
          </TabPanels>
        </Tabs>
        {/* <Tabs>
          <Flex mt="1rem" alignItems="center">
            <TabList variant="unstyle">
              <Tab>Ongoing Projects</Tab>
              <Tab>Completed Projects</Tab>
            </TabList>
            <Button
              ml="22rem"
              alignItems="center"
              backgroundColor="#407BFF"
              color="#FFFFFF"
              cursor="pointer"
              borderRadius="2rem"
            >
              <Icon as={CiCirclePlus} w="2rem" h="2rem" />
              <Text ml="1rem">Add Project</Text>
            </Button>
            <Icon as={BsThreeDotsVertical} ml="1rem" />
          </Flex>
          <TabPanels>
            <TabPanel>
              <Flex mt="2rem" flexWrap="wrap" justifyContent="space-between">
                {displayData.map((item, index) => (
                  <ProjectCard key={index} props={item} />
                ))}
              </Flex>
              <Flex justifyContent="center" alignItems="center">
                <Icon
                  as={BsChevronBarLeft}
                  onClick={handlePrev}
                  backgroundColor="#3965FF"
                  borderRadius="2rem"
                  color="#FFFFFF"
                  padding="5px"
                  width="2rem"
                  height="2rem"
                />
                <Text m="0 1rem">
                  Page {currentPage} of {totalPages}
                </Text>
                <Icon
                  as={BsChevronBarRight}
                  onClick={handleNext}
                  backgroundColor="#3965FF"
                  borderRadius="2rem"
                  color="#FFFFFF"
                  padding="5px"
                  width="2rem"
                  height="2rem"
                />
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex mt="2rem" flexWrap="wrap" justifyContent="space-between">
                {displayData.map((item, index) => (
                  <ProjectCard key={index} props={item} />
                ))}
              </Flex>
              <Flex justifyContent="center" alignItems="center">
                <Icon
                  as={BsChevronBarLeft}
                  onClick={handlePrev}
                  backgroundColor="#3965FF"
                  borderRadius="2rem"
                  color="#FFFFFF"
                  padding="5px"
                  width="2rem"
                  height="2rem"
                />
                <Text m="0 1rem">
                  Page {currentPage} of {totalPages}
                </Text>
                <Icon
                  as={BsChevronBarRight}
                  onClick={handleNext}
                  backgroundColor="#3965FF"
                  borderRadius="2rem"
                  color="#FFFFFF"
                  padding="5px"
                  width="2rem"
                  height="2rem"
                />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs> */}
      </Flex>

      <EnquiryTable columnData={enquiryColumnData} rowData={enquiryRowData} />

      <Box mt="3rem">
        <PaymentHistoryTable
          columnData={paymentHistoryColumnData}
          rowData={paymentHistoryRowData}
        />
      </Box>
    </>
  );
};
export default Project;
