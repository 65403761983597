import {
  Avatar,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Image from "views/admin/clintProfile/assets/avatar1.png";
import Personal from "./components/personal";
import BusinessInfo from "./components/businessInfo";
import Verification from "./components/verification";
import Services from "./components/services";
import Goals from "./components/goal";
import useUserDisplayName from "components/hooks/useUserDisplayName";
import { db } from "config/firebase";
import { auth } from "config/firebase";
import { getDownloadURL, uploadBytes,ref } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc,collection, getDocs,query,where, setDoc } from "firebase/firestore";
import { storage } from "config/firebase";
import { useMediaQuery } from "@mui/material";
import personal_icon from "../clintProfile/assets/Personal.png"
import maleProfilePic from "./assets/maleProfileImage.png"
import femaleProfilePic from "./assets/femaleProfileImage.png"
import userIcon from "./assets/4957136.jpg"
import businessInfo_icon from "../clintProfile/assets/Business_Info.png"
import goal_icon from "../clintProfile/assets/Goals.png"
import arrow_icon from "../clintProfile/assets/ooui_next-ltr.png"
import { transform } from "framer-motion";

const Profile = () => {
  const { displayName } = useUserDisplayName(db, auth);
  const [profilePicture, setProfilePicture] = useState("");
  const [UserId, setUserId] = useState();
  const [userData, setUserData] = useState({});
  const toast=useToast();
  const isMobile=useMediaQuery("(max-width:640px)")
  const [personalPopup,setPersonalPopup]=useState(false);
  const [businessInfoPopup,setBusinessInfoPopup]=useState(false);
  const [goalPopup,setGoalPopup]=useState(false);

  const handlePersonalPopup=()=>{
    if(personalPopup===false){
      setPersonalPopup(true);
    }else{
      setPersonalPopup(false);
    }
  }

  const handleBusinessInfoPopup=()=>{
    if(businessInfoPopup===false){
      setBusinessInfoPopup(true);
    }else{
      setBusinessInfoPopup(false);
    }
  }

  const handleGoalPopup=()=>{
    if(goalPopup===false){
      setGoalPopup(true);
    }else{
      setGoalPopup(false);
    }
  }

  const fetchUserData = async () => {
    try{
      if (UserId) {
        const ProfileDocRef=collection(db,"userProfiles");
        const q=query(ProfileDocRef,where("uid","==",UserId));
        const querySnapshot=await getDocs(q);

        if(!querySnapshot.empty){
          const data=querySnapshot.docs[0].data();
          if(data.profileImage){
            setProfilePicture(data.profileImage);
          }else{
            console.log("genderInfo:",data.personal.gender);
            if(data.personal.gender==="Male"){
              setProfilePicture(maleProfilePic);
            }else if(data.personal.gender==="Female"){
              setProfilePicture(femaleProfilePic);
            }else{
              setProfilePicture(userIcon);
            }
          }
          
        }else{
          console.log("User does not exist!");
        }
      }
    }catch(error){
      console.log(error.message);
    }
    
  };

  useEffect(() => {
    fetchUserData();
  }, [UserId]);

  useEffect(()=>{
    const unsubscribe=onAuthStateChanged(auth,(user)=>{
      if(user){
        const userid=user.uid;
        setUserId(userid);
      }else{
        console.log("user does not exist in personal section");
      }
    });

    return ()=>unsubscribe();
  },[]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log("The file is:",file);
    if (!file) {
      return;
    }

    const storageRef = ref(storage, `profilePictures/${file.name}`);
    await uploadBytes(storageRef, file);

    // Get the download URL for the uploaded file
    const downloadUrl = await getDownloadURL(storageRef);
    console.log("downloadUrl of Image:",downloadUrl);

    const imgUrlObj={profileImage:downloadUrl}
    const ProfileDocRef=collection(db,"userProfiles");
    const q=query(ProfileDocRef,where("uid","==",UserId));
    const documentSnap=await getDocs(q);

    if(!documentSnap.empty){
      const docId=documentSnap.docs[0].id;
      const docRef=doc(ProfileDocRef,docId);
      await updateDoc(docRef,imgUrlObj);
      toast({
        title:'Profile Picture Updated Successfully!',
        description:'The User Profile Picture was succesfully updated!',
        status:'success',
        duration:'2500',
        isClosable:true,
      })
    }

    fetchUserData();

    // Optionally, clear the input field after successful upload
    event.target.value = "";
  };


  return (
    <div className="d-flex flex-row mb-3">
      {isMobile?<Flex flexDirection="column" alignItems="center">
        <Flex flexDirection="column">
          <label for="actual-btn" style={{cursor:"pointer"}}><div style={{borderRadius:"100%",height:"100px",width:"100px",overflow:"hidden"}}><img src={profilePicture} style={{height:"100%",width:"100%"}} alt="Profile Image"/></div></label>
          <input
            type="file"
            id="actual-btn"
            hidden
            onChange={handleFileChange}
            style={{border:"2px solid black"}}
          />
         { /*<label
            for="actual-btn"
            style={{
              marginTop: "0.5rem",
              backgroundColor: "#407BFF",
              color: "white",
              padding: "0.5rem",
              borderRadius: "1rem",
              cursor: "pointer",
              textAlign: "center",
            }}
          >
            Edit
          </label>*/}
        </Flex>
          
        <div style={{ textAlign:"center" }}>
          <h1 style={{ fontSize: "18px", fontWeight: "bold" }}>
            {displayName}
          </h1>
          <p style={{fontSize:"12px"}}>Profile Completion : 100%</p>
        </div>
      </Flex>:<Flex>
        <Flex flexDirection="column">
        <label for="actual-btn" style={{cursor:"pointer"}}><div style={{borderRadius:"100%",height:"150px",width:"150px",overflow:"hidden"}}><img src={profilePicture} style={{height:"100%",width:"100%"}}/></div></label>
          <input
            type="file"
            id="actual-btn"
            hidden
            onChange={handleFileChange}
          />
  
        </Flex>
          
        <div style={{ marginLeft: "1rem" }}>
          <h2 style={{ fontSize: "2rem", fontWeight: "bold" }}>
            {displayName}
          </h2>
          <p>Profile Completion : 100%</p>
        </div>
      </Flex>}
      
        
      {isMobile?<div style={{display:"flex",flexDirection:"column",height:"max-content",width:"100%",alignItems:"center"}}>
      <div style={{boxShadow:"0 1px 0 gray",height:"1px",width:"88%",marginTop:"20px",marginBottom:"20px"}}></div>
        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={personal_icon} alt="personal_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:"190px"}}>Personal</p> <img src={arrow_icon} alt="arrow_icon" style={{transform:personalPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handlePersonalPopup} /></div>
        {personalPopup?<div style={{width:"100%"}}><Personal/></div>:<div></div>}

        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={businessInfo_icon} alt="businessInfo_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:"150px",minWidth:"100px"}}>Business Info</p> <img src={arrow_icon} alt="arrow_icon" style={{transform:businessInfoPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handleBusinessInfoPopup} /></div>
        {businessInfoPopup?<div style={{width:"100%"}}><BusinessInfo/></div>:<div></div>}

        <div style={{display:"flex",height:"max-content",width:"90%",alignItems:"center",padding:"2px",marginBottom:"10px"}}><img src={goal_icon} alt="goal_icon" style={{marginRight:"40px"}}/> <p style={{marginRight:"218px"}}>Goal</p> <img src={arrow_icon} alt="arrow_icon" style={{transform:goalPopup?"rotate(90deg)":"none",cursor:"pointer"}} onClick={handleGoalPopup} /></div>
        {goalPopup?<div style={{width:"100%"}}><Goals/></div>:<div></div>}
        
      </div>:<div style={{ marginTop: "2rem" }}>
        <Tabs variant="soft-rectangle" colorScheme="#FFFFFF">
          <TabList ml="4" mr="3">
            <Tab
              backgroundColor="#FFFFFF"
              mr="6"
              borderRadius="8px"
              paddingInline={"5"}
            >
              Personal
            </Tab>
            <Tab
              backgroundColor="#FFFFFF"
              mr="6"
              borderRadius="8px"
              paddingInline={"5"}
            >
              Business Info
            </Tab>
            <Tab
              backgroundColor="#FFFFFF"
              mr="6"
              borderRadius="8px"
              paddingInline={"5"}
            >
              Goals
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Personal />
            </TabPanel>
            <TabPanel>
              <BusinessInfo />
            </TabPanel>
            <TabPanel>
              <Goals />
            </TabPanel>
            <TabPanel>
              <Verification />
            </TabPanel>
            <TabPanel>
              <Services />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>}
    </div>
  );
};

export default Profile;
