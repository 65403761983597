import React, { useState } from 'react';
import {
    Box,
    Button,
    Flex,
    Icon,
    Input,
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Progress,
    Text,
    useBreakpointValue,
    Avatar,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    VStack, Circle,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngry, faMeh, faSmile, faLaugh, faFrown } from '@fortawesome/free-solid-svg-icons';
import { CheckIcon } from "@chakra-ui/icons";

const ProjectCard2 = ({ props }) => {
    const [rating, setRating] = useState(0);
    const [tempRating, setTempRating] = useState(0); // Temporary rating for modal
    const [review, setReview] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [isAlertOpen, setIsAlertOpen] = useState(false); // Confirmation box state
    const cancelRef = React.useRef();
    const textWidth = useBreakpointValue({ base: "50%", md: "auto" });

    const emojis = [
        { id: 1, icon: <FontAwesomeIcon icon={faAngry} />, label: 'Angry' },
        { id: 2, icon: <FontAwesomeIcon icon={faFrown} />, label: 'Frown' },
        { id: 3, icon: <FontAwesomeIcon icon={faMeh} />, label: 'Meh' },
        { id: 4, icon: <FontAwesomeIcon icon={faSmile} />, label: 'Smile' },
        { id: 5, icon: <FontAwesomeIcon icon={faLaugh} />, label: 'Laugh' },
    ];

    const handleSubmit = () => {
        setRating(tempRating); // Update rating permanently
        setOpenModal(false); // Close the rating modal
        setReview(''); // Clear review
        setIsAlertOpen(true); // Show confirmation alert
    };

    return (
        <>
            <Box
                p="0.8rem"
                mb="2rem"
                borderRadius="2rem"
                backgroundColor={props?.backgroundColor}
                boxShadow="0.375rem 0.75rem 1.375rem 0px rgba(69, 90, 100, 0.35)"
                cursor="pointer"
                onClick={() => setOpenModal(true)}
            >
                <Flex justifyContent="space-between">
                    <Text fontSize="12px">17 Jan 2024</Text>
                    <Icon as={BsThreeDotsVertical} />
                </Flex>
                <Flex flexDirection="column" justifyContent="center" alignItems="center">
                    <Text fontWeight="bold" fontSize="lg">{props?.category}</Text>
                    <Text fontSize="12px">{props?.subCategory}</Text>
                </Flex>
                <Flex flexDirection="column">
                    <Text fontSize="15px" fontWeight="bold">Progress</Text>
                    <Box position="relative">
                        <Progress w="100%" value={props?.progress} colorScheme={props?.progressBarColor} />
                        <Text position="absolute" right="0" p="0.3rem">{props?.progress}%</Text>
                    </Box>
                </Flex>

                {/* Avatar & Rating */}
                <Flex mt="1rem" alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center">
                        <Avatar src={props?.avatar || "default-avatar.png"} />
                        <Text w={textWidth} ml="0.3rem" fontWeight="bold">{props?.userName}</Text>
                    </Flex>
                    <Flex justifyContent="center" alignItems="center">
                        {[1, 2, 3, 4, 5].map((value) => (
                            <svg
                                key={value}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill={value <= rating ? "yellow" : "gray"} // Highlight stars based on rating
                                style={{ width: '15px', height: '15px', margin: '0 2px' }}
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        ))}
                    </Flex>
                </Flex>
            </Box>

            {/* Rating Modal */}
            <Modal isOpen={openModal} onClose={() => setOpenModal(false)} size="lg">
                <ModalOverlay />
                <ModalContent backgroundColor="#EBF2FA" borderRadius="1.5rem" maxWidth="90%">
                    <ModalHeader>
                        <Text textAlign="center" fontWeight="bold" fontSize="2xl">How was your Experience?</Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <Divider />
                    <ModalBody>
                        {/* Emoji Ratings */}
                        <Flex justify="center" mb="1rem" align="center">
                            {emojis.map((emoji) => (
                                <Box
                                    key={emoji.id}
                                    fontSize={tempRating === emoji.id ? '4rem' : '3rem'}
                                    color={tempRating === emoji.id ? '#FFD700' : '#A0AEC0'}
                                    cursor="pointer"
                                    onClick={() => setTempRating(emoji.id)}
                                    mx="0.8rem"
                                    title={emoji.label}
                                >
                                    <Box>{emoji.icon}</Box>
                                </Box>
                            ))}
                        </Flex>

                        {/* Review Input */}
                        <Text fontWeight="bold" mb="1rem" color="gray.600">Add your review</Text>
                        <Input
                            value={review}
                            onChange={(e) => setReview(e.target.value)}
                            placeholder="Share your feedback..."
                            size="lg"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleSubmit} colorScheme="blue" w="100%">
                            Submit Review
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Confirmation Alert Dialog */}
            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsAlertOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold" >


                            {/* Green Check Icon */}
                            <VStack spacing={4}>
                                <Circle size="60px" bg="green.100">
                                    <Icon as={CheckIcon} color="green.500" boxSize={8} />
                                </Circle>

                                {/* Title */}
                                {/* <Text fontSize="lg" fontWeight="bold" color="gray.700">
          Thank You for Submitting !
        </Text>

        {/* Subtext */}
                                {/* <Text fontSize="sm" color="gray.500">
          Your submission has been received.
        </Text>

        {/* Close Button */}
                                {/* <Button colorScheme="blue" onClick={onClose}>
          Close
        </Button>
        */}
                            </VStack>

                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text fontSize="lg" fontWeight="bold" color="gray.700" textAlign=" center">
                                Thank You for Submitting !
                            </Text>
                            <Text fontSize="sm" color="gray.500" textAlign=" center">
                                Your submission has been received.
                            </Text>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button colorScheme="blue" onClick={() => setIsAlertOpen(false)} ml={3}>
                                Close
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

export default ProjectCard2;
