import React from "react";
import { Flex, Text } from "@chakra-ui/react";

const EventClient = ({ data }) => {
  return (
    <Flex
      p="1rem"
      boxShadow="-3px 0px 1px 0px #407BFF"
      borderRadius="20px"
      justifyContent="space-between"
      alignItems="center"
    >
      {/* Partner Name Box */}
      <Flex
        p="0.25rem"
        m="1"
        borderRadius="15px"
        border="3.5px solid #A1D4C9"
        pl="1rem"
        pr="1rem"
      >
        <Text fontSize="1.75rem" fontWeight="bold" color="#455A64" letterSpacing="0.25rem">
          <Text as="span" color="#455A64">
            {data?.partnerName?.charAt(0)}
          </Text>
          <Text as="span" color="#A1D4C9">
            {data?.partnerName?.charAt(1)}
          </Text>
          <Text as="span" color="#455A64">
            {data?.partnerName?.charAt(2)}
          </Text>
        </Text>
      </Flex>

      {/* Service Name */}
      <Flex ml="1rem" flexDirection="column" flex="1">
        <Text fontSize="1.25rem" color="#1B2559" ml="1rem">
          {data?.serviceName}
        </Text>
      </Flex>

      {/* Time */}
      <Flex alignItems="center">
        <Text fontSize="1rem" fontWeight="medium" color="#1B2559" textAlign="right">
          {data?.time}
        </Text>
      </Flex>
    </Flex>
  );
};

export default EventClient;
